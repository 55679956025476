<template>
  <!-- PERIZIA MODAL -->
  <div>
    <div class="modal-mask" v-if="confirmModalisVisible">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"> Conferma Salvataggio Dati </slot>
          </div>
          <div class="modal-body">
            <slot name="body"> Confermando il salvataggio tutte le modifche saranno salvate in modo permanente, confermi ? </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn" @click="confirm">Conferma</button>
              <button class="btn" @click="cancel">Annulla</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="isModalVisible" @close="closeModal">
      <template v-slot:header>
        <h2 style="margin-bottom: 0px">DATI PERITO</h2>
        <button
          type="button"
          class="btn-close"
          @click="closeModal"
          aria-label="Close modal"
        >
          x
        </button>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form">
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-ltf-email">Nome</label>
                  <br />
                  <span>{{ perito_selezionato.name }}</span>
                </div>
                <div class="form-group">
                  <label for="example-ltf-email">Cognome</label>
                  <br />
                  <span>{{ perito_selezionato.surname }}</span>
                </div>

                <div class="form-group">
                  <label for="example-ltf-email">Anno Inserimento</label>
                  <br />
                  <select
                    disabled
                    v-model="perito_selezionato.anno_inserimento"
                    class="form-control edit"
                    name="onorario"
                  >
                    <option value>Seleziona</option>
                    <option
                      v-for="year in yearsRange"
                      :key="year"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                  <!--<ValidationProvider rules="required|is_valid_date|is_after_today" name="data_specifica" v-slot="{ errors }">
                                    <DatePicker isId="data_specifica_disabled" disabled v-model="perito_selezionato.anno_inserimento" />
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>-->
                </div>
                <div class="form-group">
                  <label for="example-ltf-email">Anno Nascita</label>
                  <br />
                  <span>{{ perito_selezionato.anno_nascita }}</span>
                </div>
                <div class="form-group">
                  <label for="example-ltf-email">Provincia</label>
                  <br />
                  <span>{{ perito_selezionato.provincia }}</span>
                </div>
                <div class="form-group">
                  <label for="example-ltf-email">Telefono</label>
                  <br />
                  <span>{{ perito_selezionato.tel }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Prodotti conosciuti</label>
                  <div class="selected-products" v-if="selectedProducts.length">
                    <ul class="list-product-container">
                      <li
                        class="list-product-li"
                        v-for="product in selectedProducts"
                        :key="product.id"
                        @click="
                          isEditMode
                            ? selectedProductsfunction(product.id)
                            : null
                        "
                      >
                        {{ product.name }}
                      </li>
                    </ul>
                  </div>
                  <ValidationProvider
                    rules="required"
                    name="ispettore"
                    v-slot="{ errors }"
                  >
                    <!--<span>{{ perito_selezionato.products.split('|').join(',') }}</span>-->
                    <select
                      disabled
                      v-model="perito_selezionato.products"
                      class="form-control-select edit"
                      name="ispettore"
                      multiple
                      @click="
                        selectedProductsfunction(perito_selezionato.products)
                      "
                    >
                      <option
                        v-for="product in products"
                        :key="product.id"
                        :value="product.id"
                        :class="isSelected(product.id)"
                      >
                        {{ product.name }}
                      </option>
                    </select>
                    <span class="validation-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- onorarioGrado -->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Grado</label>
                  <select
                    v-model="perito_selezionato.grade_id"
                    class="form-control"
                    disabled
                    name="onorario"
                  >
                    <option value>Seleziona</option>
                    <option
                      v-for="grade in grades"
                      :key="grade.id"
                      :value="grade.id"
                    >
                      {{ grade.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Cod. Perito</label>
                  <br />
                  <span>{{ perito_selezionato.codice_perito }}</span>
                  <!--<ValidationProvider rules="required" name="vitto" v-slot="{ errors }">
                                    <select disabled v-model="specifica.vitto" class="form-control" name="vitto">
                                        <option value>Seleziona</option>
                                        <option value="0">0</option>
                                        <option value="0.5">0,5</option>
                                        <option value="1">1</option>
                                    </select>
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>-->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Pec</label>
                  <br />
                  <span>{{ perito_selezionato.pec }}</span>
                  <!--<ValidationProvider rules="required" name="alloggio" v-slot="{ errors }">
                                    <select disabled v-model="specifica.alloggio" class="form-control" name="alloggio">
                                        <option value>Seleziona</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                    </select>
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>-->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Email</label>
                  <br />
                  <textarea
                    v-model="perito_selezionato.email"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    disabled
                    class="form-control edit"
                  ></textarea>
                  <!--<ValidationProvider rules="required" name="alloggio" v-slot="{ errors }">
                                    <select disabled v-model="specifica.alloggio" class="form-control" name="alloggio">
                                        <option value>Seleziona</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                    </select>
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>-->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Iscrizione Albo</label>
                  <br />
                  <textarea
                    v-model="perito_selezionato.iscrizione_albo"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    disabled
                    class="form-control edit"
                  ></textarea>
                  <!--<ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="km_residenza" v-slot="{ errors }">
                                    <input disabled v-model="specifica.km_residenza" type="number" class="form-control" name="km_residenza" placeholder="">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>-->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Cassa Prevvidenza</label>
                  <br />
                  <textarea
                    v-model="perito_selezionato.cassa_previdenza"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                  <!--<ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="pedaggi" v-slot="{ errors }">
                                    <input disabled v-model="specifica.pedaggi" type="number" class="form-control" name="pedaggi" placeholder="">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>-->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Polizza Rcd</label>
                  <br />
                  <textarea
                    v-model="perito_selezionato.polizza_rcd"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                  <!--<ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="km_perizia" v-slot="{ errors }">
                                    <input disabled v-model="specifica.km_perizia" type="number" class="form-control" name="km_perizia" placeholder="">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>-->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Titolo</label>
                  <select
                    v-model="perito_selezionato.titolo_id"
                    class="form-control edit"
                    disabled
                    name="onorario"
                  >
                    <option value>Seleziona</option>
                    <option
                      v-for="titolo in titoli"
                      :key="titolo.id"
                      :value="titolo.id"
                    >
                      {{ titolo.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Titolo Studio</label>
                  <select
                    v-model="perito_selezionato.titolo_studio_id"
                    class="form-control edit"
                    disabled
                    name="onorario"
                  >
                    <option value>Seleziona</option>
                    <option
                      v-for="titolo in titoli_studio"
                      :key="titolo.id"
                      :value="titolo.id"
                    >
                      {{ titolo.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-6">     
                <div class="form-group">
                  <label for="example-select">Onorario</label>
                  <select
                    disabled
                    v-model="perito_selezionato.onorario_id"
                    class="form-control"
                    name="onorario"
                  >
                    <option value>Seleziona</option>
                    <option
                      v-for="onorario in onorari"
                      :key="onorario.id"
                      :value="onorario.id"
                    >
                      {{ onorario.onorario }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Regime</label>
                  <select
                    v-model="perito_selezionato.regime_id"
                    class="form-control edit"
                    disabled
                    name="onorario"
                  >
                    <option value>Seleziona</option>
                    <option
                      v-for="regime in regimi"
                      :key="regime.id"
                      :value="regime.id"
                    >
                      {{ regime.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <!--
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Disponibilità</label>
                  <textarea
                    v-model="perito_selezionato.disponibilita"
                    name="desc_percorso_effettuato"
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                </div>
              </div>-->

              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Codice Fiscale</label>
                  <textarea
                    disabled
                    v-model="perito_selezionato.codice_fiscale"
                    name="desc_percorso_effettuato"
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">P. Iva</label>
                  <textarea
                    disabled
                    v-model="perito_selezionato.partita_iva"
                    name="desc_percorso_effettuato"
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Indirizzo</label>
                  <textarea
                    disabled
                    v-model="perito_selezionato.indirizzo"
                    name="desc_percorso_effettuato"
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Cap</label>
                  <textarea
                    disabled
                    v-model="perito_selezionato.cap"
                    name="desc_percorso_effettuato"
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Provincia</label>
                  <textarea
                    disabled
                    v-model="perito_selezionato.provincia"
                    name="desc_percorso_effettuato"
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Nome Banca</label>
                  <textarea
                    v-model="perito_selezionato.nome_banca"
                    name="desc_percorso_effettuato"
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control"
                    disabled
                  ></textarea>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="example-select">Iban</label>
                  <textarea
                    v-model="perito_selezionato.iban"
                    name="desc_percorso_effettuato"
                    id=""
                    cols="30"
                    rows="5"
                    class="form-control"
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div
                  class="form-group"
                  v-if="albo.length && albo[0]?.iscrizione !== undefined"
                >
                  <label for="example-select">Iscrizione</label>
                  <textarea
                    v-model="albo[0].iscrizione"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="form-group"
                  v-if="albo.length && albo[0]?.professione !== undefined"
                >
                  <label for="example-select">Professione</label>
                  <textarea
                    v-model="albo[0].professione"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="form-group"
                  v-if="albo.length && albo[0]?.n_iscriz_prov !== undefined"
                >
                  <label for="example-select"
                    >Numero Iscrizione/Provincia</label
                  >
                  <textarea
                    v-model="albo[0].n_iscriz_prov"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="form-group"
                  v-if="albo.length && albo[0]?.albo !== undefined"
                >
                  <label for="example-select">Albo</label>
                  <textarea
                    v-model="albo[0].albo"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="container-row-plus">
                <label class="col-md-12" for="example-select">Ruoli anno</label>
                <button
                  @click="addRuoloAnno(perito_selezionato.codice_perito)"
                  type="button"
                  class="btn btn-sm btn-light"
                  data-toggle="tooltip"
                  title="Aggiungi ruolo anno"
                >
                  <i class="fa fa-plus-circle"></i>
                </button>
              </div>
              <div
                class="col-md-4"
                v-for="(value, key) in ruoli_anno[
                  perito_selezionato['codice_perito']
                ]"
                :key="key"
              >
                <div class="form-group">
                  <!--<div>{{ value.grade_anno }},{{ value.name }},{{ value.grades_id }}</div>-->
                  <div title="elimina Ruolo" @click="eliminaRuolo(key)">
                    <i class="fa fa-minus-circle" :class="isEditMode ? 'green':'brown'"></i>
                  </div>
                  <select
                    v-model="value.grade_anno"
                    class="form-control edit"
                    disabled
                    name="onorario"
                  >
                    <option value>Seleziona</option>
                    <option
                      v-for="year in yearsRange"
                      :key="year"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                  <select
                    v-model="value.grades_id"
                    class="form-control edit"
                    disabled
                    name="onorario"
                  >
                    <option value>Seleziona</option>
                    <option
                      v-for="grade in grades"
                      :key="grade.id"
                      :value="grade.id"
                    >
                      {{ grade.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="container-row-plus">
                <label class="col-md-12" for="example-select"
                  >Fatture per anno
                </label>
                <button
                  @click="addFatturatoAnno()"
                  type="button"
                  class="btn btn-sm btn-light"
                  data-toggle="tooltip"
                  title="Aggiungi fatturato anno"
                >
                  <i class="fa fa-plus-circle"></i>
                </button>
              </div>
              <div
                class="col-md-4"
                v-for="(value, key) in fatturato"
                :key="key"
              >
                <div class="form-group">
                  <!--<div>{{ value.grade_anno }},{{ value.name }},{{ value.grades_id }}</div>-->
                  <div title="elimina fatturato" @click="eliminaFatturato(key)">
                    <i class="fa fa-minus-circle" :class="isEditMode ? 'green':'brown'"></i>
                  </div>
                  <label for="example-select">anno</label>
                  <select
                    v-model="value.anno"
                    class="form-control edit"
                    disabled
                    name="onorario"
                  >
                    <option value>Seleziona</option>
                    <option
                      v-for="year in yearsRange"
                      :key="year"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                  <label for="example-select">giorni</label>
                  <textarea
                    v-model="value.giorni"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                  <label for="example-select">importo</label>
                  <textarea
                    v-model="value.importo"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                  <label for="example-select">note</label>
                  <textarea
                    v-model="value.note"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="container-row-plus">
                <label class="col-md-12" for="example-select"
                  >Partecipazioni
                </label>
                <button
                  @click="addPartecipazioni()"
                  type="button"
                  class="btn btn-sm btn-light"
                  data-toggle="tooltip"
                  title="Aggiungi partecipazioni"
                >
                  <i class="fa fa-plus-circle"></i>
                </button>
              </div>
              <div
                class="col-md-4"
                v-for="(value, key) in result_partecipazioni_incontri"
                :key="key"
              >
                <div class="form-group">
                  <!--<div>{{ value.grade_anno }},{{ value.name }},{{ value.grades_id }}</div>-->
                  <div
                    title="elimina Partecipazione"
                    @click="eliminaPartecipazione(key)"
                  >
                  <i class="fa fa-minus-circle" :class="isEditMode ? 'green':'brown'"></i>
                  </div>
                  <label for="example-select">data evento</label>
                  <textarea
                    v-model="value.data_evento"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                  <label for="example-select">luogo</label>
                  <textarea
                    v-model="value.luogo"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                  <label for="example-select">riunione e prodotto</label>
                  <textarea
                    v-model="value.riunione_prodotto"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                  <label for="example-select">note</label>
                  <textarea
                    v-model="value.note"
                    name="desc_percorso_effettuato"
                    cols="30"
                    rows="5"
                    class="form-control edit"
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </template>
      <template v-slot:footer>
        <i
          @click="modificaDati"
          class="fa fa-fw fa-pencil-alt"
          style="color: blue; cursor: pointer"
          title="modifica"
        >
        </i>
        <button
          type="button"
          class="btn btn-primary"
          @click="salvaDati"
          aria-label="Stampa"
        >
          Salva
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="closeModal"
          aria-label="Chiudi"
        >
          Chiudi
        </button>
      </template>
    </Modal>
  </div>
  <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../Modal";
//import DatePicker from "../../components/DatePicker"
import Utility from "../../services/utility";
import userApi from "../../services/apis/user";
//import SpecificaRepository from "../../services/apis/specifica"
export default {
  name: "ModalSpecifica",
  props: ["isModalVisible", "perito_selezionato"],
  components: {
    Modal /*DatePicker*/,
  },
  data() {
    return {
      products: [],
      selectedProducts: [],
      titoli: [],
      titoli_studio: [],
      regimi: [],
      disponibilita: [],
      grades: [],
      onorari: [],
      isEditMode: false,
      ruoli_anno: {},
      albo: [],
      fatturato: [],
      result_partecipazioni_incontri: [],
      confirmModalisVisible: false,
    };
  },
  computed: {
    data_specifica() {
      return this.specifica ? this.specifica.data_specifica : "";
    },
    yearsRange() {
      const currentYear = new Date().getFullYear(); // Get the current year
      const startYear = 1950; // Start year
      const years = [];

      // Generate the range from startYear to currentYear
      for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
      }

      return years;
    },
  },
  watch: {
    perito_selezionato: {
      handler(value) {
        if (value) {
          value?.products_id?.split("|").forEach((product_id) => {
            this.selectedProductsfunction(product_id);
          });
          let cod_perito = value.codice_perito;
          if (Array.isArray(value.ruoli_anno)) {
            this.ruoli_anno = { [cod_perito]: [] };
          } else {
            this.ruoli_anno = {
              [cod_perito]: [...value.ruoli_anno[cod_perito]],
            };
          }

          this.albo = [...this.perito_selezionato.albo];

          this.fatturato = [...this.perito_selezionato.fatturato];
          this.result_partecipazioni_incontri = [
            ...this.perito_selezionato.result_partecipazioni_incontri,
          ];

          if (!this.albo.length) {
            this.albo.push({
              iscrizione: "",
              professione: "",
              n_iscriz_prov: "",
              albo: "",
            });
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    async confirm() {
      let dataApi = this.perito_selezionato;
      dataApi.products = this.selectedProducts;
      dataApi.ruoli_anno = this.ruoli_anno;
      dataApi.albo = this.albo;
      dataApi.fatturato = this.fatturato;
      dataApi.partecipazioni_incontri = this.result_partecipazioni_incontri;

      const _resp = await userApi.save_tabella_periti(dataApi).catch(() => {});

      if (_resp) {
        if (_resp.data.messagge == "all update") {
          this.$toasted.success(_resp.data.messagge).goAway(3000);
          this.closeModal();
          this.confirmModalisVisible = false;
        }
      }
    },
    cancel() {
      this.confirmModalisVisible = false;
    },
    addRuoloAnno() {
      let cod_perito = this.perito_selezionato.codice_perito;
      this.ruoli_anno[cod_perito].push({
        user_id: "",
        grades_id: "",
        grade_anno: "",
        name: "",
      });

      setTimeout(() => {
        let elements = document.getElementsByClassName("edit");
        for (let item of elements) {
          item.disabled = false;
        }
      }, 500);
    },
    eliminaRuolo(index) {
      let cod_perito = this.perito_selezionato.codice_perito;
      if (this.isEditMode) {
        this.ruoli_anno[cod_perito].splice(1, index);
      } else {
        this.$toasted
          .error("Abilitare modifica per eliminare elemento")
          .goAway(3000);
      }
    },
    addFatturatoAnno() {
      this.fatturato.push({
        anno: "",
        giorni: "",
        importo: "",
      });

      setTimeout(() => {
        let elements = document.getElementsByClassName("edit");
        for (let item of elements) {
          item.disabled = false;
        }
      }, 500);
    },
    eliminaFatturato(index) {
      if (this.isEditMode) {
        this.fatturato.splice(1, index);
      } else {
        this.$toasted
          .error("Abilitare modifica per eliminare elemento")
          .goAway(3000);
      }
    },
    addPartecipazioni() {
      this.result_partecipazioni_incontri.push({
        data_evento: "",
        luogo: "",
        note: "",
        riunione_prodotto: "",
      });

      setTimeout(() => {
        let elements = document.getElementsByClassName("edit");
        for (let item of elements) {
          item.disabled = false;
        }
      }, 500);
    },
    eliminaPartecipazione(index) {
      if (this.isEditMode) {
        this.result_partecipazioni_incontri.splice(index, 1);
      } else {
        this.$toasted
          .error("Abilitare modifica per eliminare elemento")
          .goAway(3000);
      }
    },
    modificaDati() {
      let elements = document.getElementsByClassName("edit");
      for (let item of elements) {
        item.disabled = !item.disabled;
        this.isEditMode = !item.disabled;
      }
    },
    isSelected(product_id) {
      let idIsSelected =
        this.selectedProducts.map((e) => e.id).indexOf(product_id) > -1
          ? "bkcolor-green"
          : "";
      return idIsSelected;
    },
    selectedProductsfunction(product_id) {
      //let perito_copy = { ...this.perito_selezionato };

      let prodottoSelezionato = this.products.filter(
        (product) => product.id == product_id
      );

      //console.log(prodottoSelezionato.length);
      if (!prodottoSelezionato.length) {
        console.log("prodotto non trovato");
        return;
      }

      if (this.selectedProducts.length) {
        let productsIsAlreadySelected = this.selectedProducts.filter(
          (product) => product.id == prodottoSelezionato[0].id
        );

        if (productsIsAlreadySelected.length) {
          // rimuovo il prodotto
          this.selectedProducts = this.selectedProducts.filter(
            (product) => product.id != prodottoSelezionato[0].id
          );
          return;
        }
      }

      this.selectedProducts.push(prodottoSelezionato[0]);
    },
    async get_data_input_periti() {
      const _resp = await userApi.get_data_input_periti().catch(() => {});
      console.log(_resp);

      if (_resp) {
        this.products = _resp.data.products;
        this.titoli = _resp.data.titoli;
        this.titoli_studio = _resp.data.titoli_studio;
        this.regimi = _resp.data.regimi;
        this.disponibilita = _resp.data.disponibilita;
        this.grades = _resp.data.grades;
        this.onorari = _resp.data.onorario;
      }
    },
    trimText(text) {
      return Utility.trimText(text);
    },
    closeModal() {
      this.$emit("closeModalCallback", null);
    },
    get_consorzio_object(id) {
      const _consorzio = this.consorzi_int.find((item) => item.id == id);
      return _consorzio ? `${_consorzio.codice} - ${_consorzio.name}` : id;
    },
    get_agenzia_object(id) {
      const _agenzia = this.agenzie_int.find((item) => item.id == id);
      return _agenzia
        ? `${_agenzia.codice_identificativo} - ${_agenzia.nome}`
        : id;
    },
    /* async printSpecifica() {
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await SpecificaRepository.print_specifica(this.specifica.id)

            if (_resp) {
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download = `specifica_${this.specifica.id}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            this.$store.dispatch('updateLoadingState', false);
        }*/
    salvaDati() {
      this.confirmModalisVisible = true;
    },
  },
  mounted() {
    //this.get_lista_ispettori()
    //this.get_consorzi()
    // this.get_agenzie()
    this.get_data_input_periti();
  },
};
</script>

<style scoped>
.form-control-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.selected-products ul {
  list-style-type: none;
  padding: 0;
}

.selected-products li {
  background-color: #f0f0f0;
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
}

.list-product-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 10px;
  list-style: none;
  gap: 4px;
}

.list-product-li {
  cursor: pointer;
}

.bkcolor-green {
  background-color: rgba(53, 128, 53, 0.411);
}

textarea {
  height: 50px !important;
}

.col-md-6,
.col-md-4 {
  border: 1px solid #cccccc; /* Light grey border for a subtle look */
  border-radius: 5px; /* Rounded corners for a modern look */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  padding: 10px; /* Add some padding for better spacing */
  background-color: #fff; /* Ensure background is white for consistency */
}

.col-md-6:hover,
.col-md-4:hover {
  border-color: #007bff; /* Change border color to a primary color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow on hover */
}

/* Optional: Add a bit of padding inside the columns */
.col-md-6 {
  padding: 20px;
}

.col-md-4 {
  padding: 15px;
}

.container-row-plus {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 10px;
  margin-right: 15px;
}

.row {
  justify-content: end;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  width: 300px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header,
.modal-footer {
  padding: 10px;
  background: #f1f1f1;
}
.brown {color:brown}
.green{color:green}
</style>
