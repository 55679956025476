<template>
 <!-- Footer -->
    <footer id="page-footer" class="bg-body-light">
        <div class="content py-3">
            <div class="row font-size-sm">
                <!-- 
                    <div @click="onOffCookie" title="disabilita cookie" class="col-sm-6 order-sm-2 py-1 text-center text-sm-right cursor-question">
                        &#127850; 
                    </div> 
                -->
                <div class="col-sm-6 order-sm-1 py-1 text-center text-sm-left">
                    <a class="font-w600" href="#">ARA1857 S.p.A.</a> &copy; <span data-toggle="year-copy">{{thisYear}}<b> Versione 2.{{version}}</b></span>
                </div>
            </div>
        </div>
    </footer>
<!-- END Footer -->
</template>

<script>
  import UserRepository from '../services/apis/user'

export default {

    props:['version'],

    data(){
        return {
            thisYear: new Date().getFullYear()
        }
    },

    methods: {
        async onOffCookie (){
            await UserRepository.update_user_cookie({
                cookieClarity:false
            })
            //window.clarity('consent', false)
        }
    }
}
</script>



<style scoped>
.cursor-question {
    cursor: pointer;
}
</style>