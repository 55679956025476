<template>
    <div class="bg-image">
        <div class="hero-static bg-white-95">
            <div class="content">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-4">
                        <!-- Sign In Block -->
                        <div class="block block-themed block-fx-shadow mb-0">
                            <div class="block-header">
                                <h3 class="block-title">LOGIN</h3>
                                <router-link to="/recupera-password">
                                    <div class="block-options">
                                        <a class="btn-block-option font-size-sm">Password dimenticata?</a>
                                    </div>
                                </router-link>
                            </div>
                            <div class="block-content">
                                <div class="p-sm-3 px-lg-4 py-lg-5">
                                    <h1 class="mb-2">MyARA 1857</h1>
                                    <p>Il portale di ARA 1857 dedicato ai propri periti assicurativi.</p>

                                    <!-- Sign In Form -->
                                    <ValidationObserver ref="observer" v-slot="{ invalid }">
                                        <form class="js-validation-signin">
                                            <div class="py-3">
                                                <div class="form-group">
                                                    <label for="example-text-input">Email</label>
                                                    <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
                                                        <input v-model="email" type="text" class="form-control" name="email" placeholder="Email">
                                                        <span class="validation-error">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="form-group">
                                                    <label for="example-text-input">Password</label>
                                                    <ValidationProvider rules="required" name="Password" v-slot="{ errors }">
                                                        <input v-model="password" type="password" class="form-control" name="password" placeholder="Password">
                                                        <span class="validation-error">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-md-6 col-xl-5">
                                                    <button :disabled="invalid" v-on:click.prevent="login" class="btn btn-block btn-primary">
                                                        <i class="fa fa-fw fa-sign-in-alt mr-1"></i> Entra
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </ValidationObserver>
                                    <!-- END Sign In Form -->
                                </div>
                            </div>
                        </div>
                        <!-- END Sign In Block -->
                    </div>
                </div>
            </div>
            <!-- <div v-if="showCookieClarity">
                <CookieClarity :cookieClarity="showCookieClarity" @setClarityCookie="acceptCookie" @setFalseClarityCookie="rejectCookie" />
            </div> -->
            <Copyright />
        </div>
    </div>
</template>

<script>

import Copyright from '../components/Copyright'
import AuthenticationRepository from '../services/apis/authentication'
import UserRepository from '../services/apis/user'
//import CookieClarity from './Clarity/ConsensoClarity.vue'


export default {
    data(){
        return {
            email:'',
            password:'',
            showCookieClarity:false,
            authProps:''
        }
    },
    components: {
        Copyright,
       //CookieClarity
    },
    methods: {
        login: async function(){

            this.$store.dispatch('updateLoadingState', true)

            let obj = {
                "email":this.email,
                "password":this.password
            }

            let auth = await AuthenticationRepository.login(obj).catch((err) => {            
                if(err.status == 401){
                    this.$toasted.error('Errore: email o password non validi').goAway(3000)
                }
            })

            this.$store.dispatch('updateLoadingState', false)

            if(auth){
                this.authProps = auth
               /*
                    per clarity
                if(!auth.data.data.user.cookieClarity){
                        this.showCookieClarity = true
                        this.$store.dispatch('updateAccessTokenState',  this.authProps.data.data.token) 
                    }else{
                        this.afterCallCookieClarity()
                    }
                    
                */
                this.afterCallCookieClarity()

            }  
        },
        async afterCallCookieClarity(){
                this.$store.dispatch('updateAccessTokenState',  this.authProps.data.data.token) 
                let user =  this.authProps.data.data.user
                user.service_token =  this.authProps.data.data.service_token
            
                this.$store.dispatch('updateUserObjState', user)  
                this.$store.dispatch('updateLogginState', true)    

                if( this.authProps.data.data.user.role === "perito"){
                    const _resp = await UserRepository.get_ispettori_associati().catch(() => {})

                    if(_resp){
                        this.$store.dispatch('updateIspettoriObjState', _resp.data.data)  
                    }
                }
                
                if( this.authProps.data.data.user.role != 'perito' &&  this.authProps.data.data.user.role != 'ispettore'){
                    this.$store.dispatch('updateChoiseState','specifiche')
                    this.$router.push({ name: 'dashboard'})
                }else {
                    this.$router.push({ name: 'scegli-sezione' })
                }
        },
        async acceptCookie() {
            console.log("Cookie accettati.");
            this.showCookieClarity = false;
            let callAcceptCookie = await UserRepository.update_user_cookie({
                cookieClarity:true
            })
            console.log(callAcceptCookie)
            this.afterCallCookieClarity()
        // Puoi aggiungere ulteriori azioni qui (es. abilitare Clarity)
        },
        async rejectCookie() {
            console.log("Cookie rifiutati.");
        /*    let callAcceptCookie = await UserRepository.update_user_cookie({
                cookieClarity:false
            })
            console.log(callAcceptCookie)*/
            this.showCookieClarity = false;
            this.afterCallCookieClarity()
        // Puoi aggiungere ulteriori azioni qui (es. disabilitare Clarity)
        },

    },
    mounted(){
        this.$store.dispatch('updateLoadingState', false)
    }
}
</script>